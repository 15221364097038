::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    border-radius: 5px;
}
::-webkit-scrollbar-track {
    background: #E5EEF5; 
    border-radius: 5px;
}
::-webkit-scrollbar-thumb {
    background: #2271CA !important;  
    border-radius: 5px;
}
::-webkit-scrollbar-thumb:hover {
    background: #2271CA!important;
    outline: 1px solid slategrey;
    border-radius: 5px;
}
 
body {
    overflow-x: hidden;
}